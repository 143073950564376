import { assert } from "./assert";
const POSSIBLE_SIZES = [
    "0",
    "0v",
    "0h",
    "4",
    "4v",
    "4h",
    "8",
    "8v",
    "8h",
    "16",
    "16v",
    "16h",
    "24",
    "24v",
    "24h",
    "32",
    "32v",
    "32h",
    "40",
    "40v",
    "40h",
    "48",
    "48v",
    "48h",
    "56",
    "56v",
    "56h",
    "64",
    "64v",
    "64h",
    "80",
    "80v",
    "80h",
    "120",
    "120v",
    "120h",
];
export function isPossibleSize(size) {
    return POSSIBLE_SIZES.some((possibleSize) => possibleSize === size || parseInt(possibleSize) === size);
}
export function assertIsPossibleSize(num, ...additionalMessageEntries) {
    assert(isPossibleSize(num), () => {
        return `Invalid size: ${num}!\nPossible sizes ${POSSIBLE_SIZES.join(", ")}`;
    }, ...additionalMessageEntries);
}
export function getSpacerWidth(spacer) {
    var _a;
    if (!spacer) {
        return 0;
    }
    const innerSpacerDiv = spacer.shadowRoot.querySelector("div");
    return (_a = innerSpacerDiv === null || innerSpacerDiv === void 0 ? void 0 : innerSpacerDiv.offsetWidth) !== null && _a !== void 0 ? _a : spacer.offsetWidth;
}
/*
TODO: activate when typescript 4.x compatible
const POSSIBLE_SIZES = [
  "4",
  "8",
  "16",
  "24",
  "32",
  "40",
  "48",
  "64",
  "80",
  "120",
] as const;
type PossibleSizeType = typeof POSSIBLE_SIZES[number]; // Union type of all possible sizes
type Vertical<T extends PossibleSizeType> = `${T}v`;
type Horizontal<T extends PossibleSizeType> = `${T}h`;
type SpacerSizeV = Vertical<PossibleSizeType>;
type SpacerSizeH = Horizontal<PossibleSizeType>;
export type SpacerSize = PossibleSizeType | SpacerSizeV | SpacerSizeH;
*/
